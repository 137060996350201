import {authenticate} from "../api/admins";

const InternalService = {}


InternalService.signInEmailRequest = async (email, password) => {
	return await authenticate(email, password).then(({data}) => data).catch((error) => {
		if (error.response.status === 401) {
			return error.response.data;
		}
		return error;
	});
}
export default InternalService