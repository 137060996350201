import { API_BASE_URL } from "configs/AppConfig";

const baseUrl = API_BASE_URL + "admin/";
const baseWebUrl = API_BASE_URL;

const endpoints = {
  getFamilies: baseUrl + "families",
  getFamilyMembers: baseUrl + "family-members",
  createFamilyMember: baseUrl + "family-members",
  deleteFamilyMember: (id) => baseUrl + "family-members/" + id,
  updateFamilyMember: (id) => baseUrl + "family-members/" + id,
  getUsers: baseUrl + "users",
  getCaregivers: baseUrl + "caregivers",
  getRecipients: baseUrl + "recipients",
  createRecipient: baseUrl + "recipients",
  updateRecipient: (id) => baseUrl + "recipients/" + id,
  createCaregiver: baseUrl + "caregivers",
  getCaregiver: (id) => baseUrl + "caregivers/" + id,
  deleteCaregiver: (id) => baseUrl + "caregivers/" + id,
  updateCaregiver: (id) => baseUrl + "caregivers/" + id,
  getPages: baseUrl + "pages",
  createPage: baseUrl + "pages",
  updatePage: (id) => baseUrl + "pages/" + id,
  deletePage: (id) => baseUrl + "pages/" + id,
  webPage: (uri) => baseWebUrl + "page/" + uri,
  getAdmins: baseUrl + "admins",
  createAdmin: baseUrl + "admins",
  updateAdmin: (id) => baseUrl + "admins/" + id,
  deleteAdmin: (id) => baseUrl + "admins/" + id,
  getCurrentUser: baseUrl + "profile",
  logout: baseWebUrl + "logout",
  getSmsLogs: baseUrl + "sms-logs",
  getStatistics: baseUrl + "statistics",
  authenticate: baseWebUrl + "login",
};

export default endpoints;
