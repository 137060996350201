import { AxiosRequestConfig } from "axios";
import endpoints from "../endpoints";
import Admin from "../models/admin";
import axios from "auth/FetchInterceptor";

export function getAdmins(): Promise<Array<Admin>> {
  return axios
    .get(endpoints.getAdmins)
    .then(({ data: { data } }) => data.map((p) => new Admin(p)));
}

export function saveAdmin(admin: Admin): Promise<object> {
  if (admin.id) {
    return axios
      .patch(endpoints.updateAdmin(admin.id), admin)
      .then(({ data }) => data);
  } else {
    return axios.post(endpoints.createAdmin, admin).then(({ data }) => data);
  }
}

export function deleteAdmin(admin: Admin): Promise<AxiosRequestConfig> {
  return axios.delete(endpoints.deleteAdmin(admin.id));
}

export function getCurrentUser() {
  return axios
    .get(endpoints.getCurrentUser)
    .then(({ data: { data } }) => new Admin(data));
}

export function authenticate(email, password) {
  return axios.post(endpoints.authenticate, { email, password });
}
