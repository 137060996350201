import {
  DashboardOutlined,
  LayoutOutlined,
  FundOutlined,
  UserOutlined,
  MessageOutlined,
  ClockCircleOutlined,
UserSwitchOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.system",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-pages",
        path: `${APP_PREFIX_PATH}/dashboards/pages`,
        title: "sidenav.dashboard.pages",
        icon: LayoutOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-admins",
        path: `${APP_PREFIX_PATH}/dashboards/admins`,
        title: "sidenav.dashboard.admins",
        icon: UserSwitchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-sms-logs",
        path: `${APP_PREFIX_PATH}/dashboards/sms-logs`,
        title: "sidenav.dashboard.sms-logs",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-task-management",
        path: `https://dev.bettercare.co.il/betterCare/superAdmin/login.php`,
        title: "sidenav.dashboard.task-management",
        icon: ClockCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-users",
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        title: "sidenav.dashboard.users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "sidenav.dashboard.sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
