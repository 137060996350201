import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
} from "../actions/Auth";

import InternalService from 'services/InternalService'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const result = yield call(InternalService.signInEmailRequest, email, password);
			if (typeof result.success !== 'undefined' && !result.success) {
				yield put(showAuthMessage(result.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, result.token);
				yield put(authenticated(result.token));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
	  localStorage.removeItem(AUTH_TOKEN);
	  yield put(signOutSuccess());
	});
}


export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
  ]);
}
