import moment from "moment/moment";
import { deleteAdmin } from "../api/admins";

export default class Admin {
  public id: number = null;

  public email: string = "";
  public password: string = "";
  public createdAt: moment.Moment = null;

  constructor(config?: any) {
    if (!config) {
      this.createdAt = moment();
      return;
    }
    this.id = config.id;
    this.email = config.email;
    this.createdAt = moment(config.createdAt);
  }

  public clone() {
    return new Admin(this);
  }

  public baseFilled() {
    return this.email && this.password;
  }

  public delete() {
    return deleteAdmin(this);
  }

  public key() {
    return this.id;
  }
}
